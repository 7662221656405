import { REQUIRED_FIELD, INVALID_FIELD } from '@/utils/validationMessagesUtils'

export default {
  confirmaSenhaErrors () {
    const errors = []
    if (!this.$v.item.confirmaSenha.$dirty) return errors
    !this.$v.item.confirmaSenha.required && errors.push(REQUIRED_FIELD)
    !this.$v.item.confirmaSenha.sameAsPassword && errors.push(INVALID_FIELD)
    return errors
  },

  senhaErrors () {
    const errors = []
    if (!this.$v.item.senha.$dirty) return errors
    !this.$v.item.senha.required && errors.push(REQUIRED_FIELD)
    return errors
  }
}