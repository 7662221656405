<template>
  <v-dialog
    v-model="isShow"
    max-width="500">
    <v-card>
      <v-card-title class="modal-cadastro-titulo">
        Perfil
      </v-card-title>

      <v-card-text class="modal-cadastro-form">
        <v-row>
          <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
            <v-text-field v-model="item.name" label="Nome completo:" @input="$v.item.name.$touch()" @blur="$v.item.name.$touch()" disabled />
          </v-col>
          <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
            <v-text-field v-model="item.username" label="Username:"  @input="$v.item.username.$touch()" @blur="$v.item.username.$touch()" disabled />
          </v-col>
          <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
            <v-text-field v-model="item.email" label="Email:"  @input="$v.item.email.$touch()" @blur="$v.item.email.$touch()" disabled />
          </v-col>
          <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
            <v-text-field type="tel" v-model="item.phone" label="Telefone:"  v-mask="['(##) ####-####', '(##) #####-####']" disabled />
          </v-col>
          <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
            <v-text-field type="password" v-model="item.senha" label="Senha:" :error-messages="senhaErrors"  @input="$v.item.senha.$touch()" @blur="$v.item.senha.$touch()" />
          </v-col>
          <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
            <v-text-field type="password" v-model="item.confirmaSenha" label="Confirmar senha:" :error-messages="confirmaSenhaErrors" @input="$v.item.confirmaSenha.$touch()" @blur="$v.item.confirmaSenha.$touch()" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="modal-cadastro-footer">
        <v-btn :color="variables.colorPrimary" @click="closeModal" class="br-btn br-btn-cancelar" :disabled="loading">Cancelar</v-btn>
        <v-btn type="submit" :color="variables.colorPrimary"  @click.prevent="submit" class="br-btn" :loading="loading">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Events from '@/core/service/events'
import { mask } from 'vue-the-mask'
import { required, sameAs } from 'vuelidate/lib/validators'
import variables from '@/assets/styles/helpers/_variables.scss'
import { formatPhone } from '@/core/service/utils'
import { mapActions } from 'vuex'
import { map } from 'lodash'
import validation from './validation'

export default {
  name: '',
  directives: { mask },
  components: {},
  data: () => ({
    isShow: false,
    loading: false,
    item: {}
  }),
  mounted () {
    const self = this
    Events.$on('perfil::openModalPerfil', item => {
      self.isShow = true
      self.item = item
    })
  },
  computed: {
    ...validation,
    variables: () => variables
  },
  methods: {
    ...mapActions('users', ['editarItem', 'getItens']),
    submit () {
      if (this.$v.item.$invalid) return

      const self = this
      this.loading = true

      const dados = {
        name: self.item.name,
        username: self.item.username.toLowerCase(),
        email: self.item.email ? self.item.email.toLowerCase() : undefined,
        phone: formatPhone(self.item.phone),
        roleId: self.item.roleId,
        userEntityIds: (() => map(self.item.userEntities, userEntity => userEntity.id))()
      }

      if (self.item.id && self.item.id !== null && self.item.id !== '') dados.id = self.item.id

      if (self.item.senha) dados.password = self.item.senha

      self.usuarioEditSave(dados)
    },

    usuarioEditSave (val) {
      const self = this
      self.editarItem(val).then(() => {
        self.closeModal()
        self.loading = false
        self.getItens()
        Events.$emit('snackbarCadastro::msg', {
          toggle: true,
          type: 'success',
          msg: 'Edição realizado com sucesso!'
        })
      }).catch(err => {
        self.loading = false
        Events.$emit('snackbarCadastro::msg', {
          toggle: true,
          type: 'error',
          msg: err.error
        })
      })
    },

    closeModal () {
      const self = this
      self.isShow = false
      self.item = {}
    }
  },
  validations () {
    return {
      item: {
        senha: { required },
        confirmaSenha: { 
          required,
          sameAsPassword: sameAs('senha')
        }
      }
    }
  }
}
</script>

<style lang="scss">
  @import '../../../assets/styles/components/modal-cadastro';
</style>